import React from "react";
import "./App.css"; // Make sure to create an App.css file for styling

function App() {
  return (
    <div className="App">
      <div className="left-section">
        <div className="left-content">
          <div className="company-logo">
            {/* Insert your logo here */}
            <img src="/OAM_logo.png" alt="Logo" />
          </div>
          <div className="first-content">
            <h1>
              We make your <span className="first-content-a">AUSSIE DREAM</span>
            </h1>
            <h1>come true</h1>
            <p className="sub-title">
              Our website is currently being developed.
            </p>
          </div>
          <div className="card-view">
            {/* Insert image in this div */}
            <div className="detail-view">
              <div className="image-placeholder">
                <img src="/Satheesan.jpeg" alt="Profile Pic"></img>
              </div>
              <div className="card-content">
                <h2>Mr. Satheesan Jerome</h2>
                <p className="second-sub-title">
                  OMARA Registered Migration Agent
                </p>
                <div className="services">
                  <ul>
                    <li>Protection Visa</li>
                    <li>Visa Cancellation Review</li>
                    <li>Skill Migration</li>
                  </ul>

                  <ul>
                    <li>Student Visa</li>
                    <li>Visitor Visa</li>
                    <li>Family Visa</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="linkedin-logo">
              <a
                href="https://www.linkedin.com/in/jerome-shatheesan-a7285093/"
                target="_blank"
              >
                {" "}
                <img src="/linkedin_icon.png" alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="right-section">
        {/* Insert QR code here */}
        {/* Ensure you replace '/path-to-your-qr-code.png' with actual path to QR code image*/}
        <div className="cycle-div">
          <div>
            <h1 className="right-content">We're all ears</h1>
          </div>
          <div className="qr-code">
            <p>
              <a href="https://wa.me/61447019749">
                {" "}
                <img src="/qr-code.png" alt="QR Code" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
